



























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    image: {
      type: String,
      required: false
    }
  }
})
